













































import { Component, Vue } from 'vue-property-decorator';
import user from "@/store/user";
import toaster, { ToastImpl } from '@/store/toaster';

@Component
export default class LoginForm extends Vue {

  private email = "";
  private password = "";
  private error = false;
  private confirmation = false;
  private showForgotPassword = false;

  mounted(): void {
    if (user.currentUser && !user.currentUser.emailConfirmed) {
      this.confirmation = true;
    }
  }

  goTo(path: string): void {
    this.$router.push(path);
  }

  forgotPassword(): void {
    this.showForgotPassword = true;
  }

  resetPassword(): void {
    user.dispatchSendResetPassword(encodeURIComponent(this.email)).then(r => {
      if (r) {
        this.showForgotPassword = false;
        toaster.dispatchToast(new ToastImpl('success', `${this.$t('auth.resetPasswordSentTitle')}`, `${this.$t('auth.resetPasswordMsg')}`));
      } else {
        toaster.dispatchToast(new ToastImpl('danger', `${this.$t('common.error')}`, `${this.$t('common.errorMsg')}`));
      }
    });
  }

  sendConfirmationLink(): void {
    user.dispatchSendConfirmationEmail(user.currentUser!.id).then(r => {
      if (r) {
        toaster.dispatchToast(new ToastImpl('success', `${this.$t('view.account.activateSentTitle')}`, `${this.$t('view.account.activateSent')}`));
      } else {
        toaster.dispatchToast(new ToastImpl('danger', `${this.$t('common.error')}`, `${this.$t('view.account.activateSentError')}`));
      }
    });
  }

  login(): void {
    user.dispatchLogin({ email: this.email, password: this.password }).then((r: boolean) => {
      if (r) {
        if (user.currentUser && !user.currentUser.emailConfirmed) {
          this.confirmation = true;
          return;
        }
        if (user.redirectRoute) {
          const route = user.redirectRoute;
          user.redirectRoute = undefined;
          this.$router.push(route);
        } else {
          this.$router.push("/");
        }
      } else {
        this.error = true;
      }
    });
  }

}
