






import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/components/Auth/LoginForm.vue';

@Component({
  components: {
    LoginForm,
  },
  metaInfo() {
    return {
      title: `${this.$t('meta.loginTitle')}`,
      htmlAttrs: {
        lang: this.$root.$i18n.locale
      },
      meta: [
        {
          name: 'description',
          content: `${this.$t('meta.loginDescription')}`
        },
        {
          name: 'og:title',
          content: `${this.$t('meta.loginTitle')}`
        },
        {
          name: 'og:description',
          content: `${this.$t('meta.loginDescription')}`
        },
        {
          name: 'twitter:title',
          content: `${this.$t('meta.loginTitle')}`
        },
        {
          name: 'twitter:description',
          content: `${this.$t('meta.loginDescription')}`
        }
      ]
    }
  }
})
export default class Login extends Vue {

}
